import React, {useState} from "react";
import {Alert, Button, Form, Modal} from "react-bootstrap";

export default function ClientCredentialsLoginModal(props: {
  show: boolean,
  onHide: () => void,
  errorMessage?: string,
  onSubmit: (clientId: string, clientSecret: string) => void
}) {
  const [clientIdValue, setClientIdValue] = useState<string>('');
  const [clientSecretValue, setClientSecretValue] = useState<string>('');

  function handleSubmitClick() {
    props.onSubmit(clientIdValue, clientSecretValue);
  }

  return <Modal id="loginFormModal" show={props.show}
                onHide={props.onHide}>
    <Modal.Header closeButton>
      <Modal.Title>Login</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <Form>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Cognito Client ID</Form.Label>
          <Form.Control type="text" placeholder="Client ID..."
                        value={clientIdValue}
                        onChange={(e) => setClientIdValue(e.target.value)}
          />
          <Form.Text className="text-muted">
            This is the client ID for the <strong>Vaccination</strong> user pool
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Cognito Client Secret</Form.Label>
          <Form.Control type="password" placeholder="Client secret..."
                        value={clientSecretValue}
                        onChange={(e) => setClientSecretValue(e.target.value)}/>
        </Form.Group>
      </Form>

      {props.errorMessage &&
        <Alert className="alert-danger">
          {props.errorMessage}
        </Alert>}
    </Modal.Body>

    <Modal.Footer>
      <Button variant="secondary" onClick={props.onHide}>Cancel</Button>
      <Button variant="primary" onClick={handleSubmitClick}>Login</Button>
    </Modal.Footer>
  </Modal>;
}
