import React, {useState} from "react";
import {Button, Col, Container, Form, Row, Tab, Tabs} from "react-bootstrap";
import SyntaxHighlighter from "react-syntax-highlighter";
import {docco} from "react-syntax-highlighter/dist/esm/styles/hljs";

import {ValueSet} from "fhir/r4";

import {VaccinationAPI} from "../service/VaccinationAPI";
import ValueSetDisplayTable from "./ValueSetDisplayTable";


export default function ValueSetDisplay(props: { idx: number }): JSX.Element {
  const {idx} = props;

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const [allValues, setAllValues] = useState<ValueSet | undefined>(undefined);
  const [queryKey, setQueryKey] = useState<string>('vaccine-code');
  const [filterString, setFilterString] = useState<string>('');

  const queries: { [idx: string]: any } = {
    // TODO: disease-code
    'vaccine-code': {
      title: 'Vaccine Type', valueSetCode: 'vaccine-code',
    },
    'target-disease': {
      title: 'Vaccine Target Disease', valueSetCode: 'target-disease',
    },
    'body-site': {
      title: 'Body Site', valueSetCode: 'body-site',
    },
    'administration-route': {
      title: 'Drug Administration Route', valueSetCode: 'administration-route',
    },
    'status': {
      title: 'Immunization Status', valueSetCode: 'status',
    },
    'status-reason': {
      title: 'Immunization Status Reason', valueSetCode: 'status-reason',
    },
  }

  function getKeys(dict: {}): string[] {
    // This is a convenience method, because a {dict} doesn't have any built-in iterators
    const keys: string[] = [];
    for (let k in dict) {
      keys.push(k);
    }
    return keys;
  }

  async function getAndDisplayValueSet() {
    setErrorMessage(undefined);
    setAllValues(undefined);
    setFilterString('');

    const api = new VaccinationAPI(window.sessionStorage.getItem('accessToken') || '');

    const queryData = queries[queryKey];

    try {
      const valueSet = await api.getValueSet(queryData.valueSetCode);
      setAllValues(valueSet);
    } catch (e) {
      if (typeof e === 'string')
        setErrorMessage(e);
      else if (e instanceof Error)
        setErrorMessage((e as Error).message)
      else {
        setErrorMessage('Unknown error occurred');
        console.error(e);
      }
    }
  }

  return <>
    <Form>
      <Container className="p-3">
        <Form.Label column={true}>Select Value Set</Form.Label>
        <Row>
          <Col sm={5}>
            <Form.Select value={queryKey} onChange={e => setQueryKey(e.target.value)}>
              {getKeys(queries).map(k => <option value={k} key={k}>{queries[k].title}</option>)}
            </Form.Select>
          </Col>
          <Col sm={2}>
            <Button variant="outline-primary" className="m-1"
                    onClick={() => getAndDisplayValueSet()}>Refresh data</Button>
          </Col>
        </Row>
      </Container>

      {errorMessage && <Container className="p-3">
        <div className="alert alert-danger">
          {errorMessage}
        </div>
      </Container>}

      {allValues && (!allValues.jurisdiction || allValues.jurisdiction.length === 0) && <Container className="p-3">
        <p>No values found matching this query</p>
      </Container>}

      {allValues && <Container className="p-3">
        <Tabs defaultActiveKey={`vst-value-set-viewer`}
              className="mb-3">
          <Tab eventKey={`vst-value-set-viewer`}
               title="Value Set">
            <Form.Group>
              <Form.Label column={true}>{allValues.jurisdiction?.length} values. Search to filter...</Form.Label>
              <Form.Control type="text"
                            value={filterString}
                            onChange={(e) => setFilterString(e.target.value)}
                            placeholder="Filter..."/>
            </Form.Group>

            <ValueSetDisplayTable idx={idx} valueSet={allValues} filter={filterString}/>
          </Tab>
          <Tab eventKey={`vst-value-set-json`}
               title="JSON">
            <SyntaxHighlighter language="json" style={docco}>
              {JSON.stringify(allValues, null, 2)}
            </SyntaxHighlighter>
          </Tab>
        </Tabs>
      </Container>}

    </Form>
  </>;
}
