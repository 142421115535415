import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Container} from "react-bootstrap";
import {AxiosResponse} from "axios";

import MainNavbar from "../components/MainNavbar";
import * as Auth from "../service/Auth";
import {switchEnv} from "../config";
import {Environment} from "../types";

function OAuthCallbackScreen() {
  const [envName, setEnvName] = useState<Environment>('dev');

  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const code = params.get('code');
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    // NB: this is duplicated from App.tsx - this is a screen which doesn't inherit from there
    const envName = window.sessionStorage.getItem('envName') || 'dev';

    switchEnv(envName as Environment);
    setEnvName(envName as Environment);
  }, []); // NB: empty array important to prevent multiple firing of event

  function doSwapCodeForToken() {
    Auth.handleCallback(code!)
      .then((response: AxiosResponse) => {
        const accessToken = response.data.access_token;
        const refreshToken = response.data.refresh_token;

        window.sessionStorage.setItem('accessToken', accessToken);
        window.sessionStorage.setItem('refreshToken', refreshToken);

        navigate('/');
      })
      .catch(e => {
        console.error(e);
        try {
          setErrorMessage(JSON.stringify(e));
        } catch (e2) {
          setErrorMessage(e);
        }
      });
  }

  useEffect(() => {
    doSwapCodeForToken();
  }, []); // NB: empty array important - only run once

  return <>
    <MainNavbar currentEnvName={envName}/>

    {errorMessage && <Container className="p-3">
      <div className="alert alert-danger">
        {errorMessage}
      </div>
    </Container>}

    {code}
  </>
}

export default OAuthCallbackScreen
