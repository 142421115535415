import {ValueSet} from "fhir/r4";
import constants from "../constants";
import {Table} from "react-bootstrap";
import React from "react";
import {getIdentifierSystemDisplayText} from "../helpers";


export default function ValueSetDisplayTable(props: { idx: number, valueSet: ValueSet, filter: string }): JSX.Element {
  const {idx, valueSet, filter: filterString} = props;

  const filterTokens = filterString.toLowerCase().split(' ');

  return <Table striped bordered hover size="sm" key={`vst-${idx}`}>
    <thead>
    <tr>
      <th>#</th>
      <th>Display</th>
      <th>Code</th>
    </tr>
    </thead>

    <tbody>
    {valueSet.jurisdiction?.map((cc, n1) => {
      return cc.coding?.filter(coding => {
        const searchableString = (coding.display + ' ' + coding.code)
          .toLowerCase();

        for (let token of filterTokens) {
          if (searchableString.search(token) >= 0)
            return true;
        }

        return false;
      }).map((coding, n2) =>
        <tr key={`vstr-${idx}-${n1}-${n2}`}>
          <td>{n1}</td>
          <td>{coding.display}<br/>
            {cc.text && <div className="small text-muted">{cc.text}</div>}
          </td>
          <td>{coding.code}<br/>
            <div className="small text-muted">{getIdentifierSystemDisplayText(coding.system)}</div>
          </td>
        </tr>)
    })}
    </tbody>
  </Table>;
}

