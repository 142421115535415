import React from "react";
import {Alert, Button, Modal} from "react-bootstrap";
import {EnvironmentNames} from "../types";
import {environments} from "../config";

export default function ChooseEnvModal(props: {
  show: boolean,
  onHide: () => void,
  errorMessage?: string,
  onSubmit: (env: string) => void
}) {
  function handleSubmitClick(env: string) {
    props.onSubmit(env);
  }

  return <Modal id="chooseEnvModal" show={props.show}
                onHide={props.onHide}>
    <Modal.Header closeButton>
      <Modal.Title>Switch to environment</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <div className="btn-group-vertical" role="group">
        {EnvironmentNames.map(env =>
          <button onClick={() => handleSubmitClick(env)}
                  key={`btn-choose-env-${env}`}
                  className="btn btn-lg btn-outline-secondary">{environments[env].longName}</button>)}
      </div>

      {props.errorMessage &&
        <Alert className="alert-danger">
          {props.errorMessage}
        </Alert>}
    </Modal.Body>

    <Modal.Footer>
      <Button variant="secondary" onClick={props.onHide}>Cancel</Button>
    </Modal.Footer>
  </Modal>;
}
