import {Button, ButtonGroup, Container, Dropdown, DropdownButton, Navbar} from "react-bootstrap";
import React from "react";
import * as Auth from "../service/Auth";
import {Environment} from "../types";

export default function MainNavbar(props: {
  onLoginButtonClick?: () => void,
  onClientCredentialsLoginClick?: () => void,
  onEnvButtonClick?: () => void,
  currentEnvName?: Environment
}) {

  function handleLoginClick() {
    const url = Auth.getLoginUiUrl();
    window.location.replace(url);
  }

  return <Navbar bg="primary" variant="dark">
    <Container>
      <Navbar.Brand href="/">NCDS Admin UI</Navbar.Brand>
      <Navbar.Toggle/>

      <Navbar.Collapse className="justify-content-end">
        <Navbar.Text>

          <ButtonGroup>
            <Button variant="warning"
                    onClick={props.onLoginButtonClick || handleLoginClick}>
              Login</Button>

            {props.currentEnvName &&
              <Button variant="outline-warning" onClick={props.onEnvButtonClick}>
                {props.currentEnvName} <span className="text-muted small">env</span>
              </Button>}

            <DropdownButton as={ButtonGroup} variant="outline-warning" title="" id="bg-nested-dropdown">
              {props.onClientCredentialsLoginClick &&
                <Dropdown.Item eventKey="2" className="text-dark"
                               onClick={props.onClientCredentialsLoginClick}>
                  Login with client credentials
                </Dropdown.Item>}
            </DropdownButton>

          </ButtonGroup>
        </Navbar.Text>
      </Navbar.Collapse>
    </Container>
  </Navbar>;
}
