const CONSTANTS = {
  identifierSystem: {
    chiNumber: 'https://phfapi.digitalhealthplatform.net/fhir/chinumber',
    gmcNumber: 'http://fhir.nhs.scot.uk/national/gmc-number',
    nmcNumber: 'https://fhir.hl7.org.uk/Id/nmc-number',
    snomedCt: 'http://snomed.info/sct',
    healthBoardCypher: 'https://nds.nes.digital/HBCypherSystem',
    organizationType: 'https://fhir.ndp.scot/organization-type',
    isdCode: 'https://nds.nes.digital/ISD_code',
    locationType: 'https://fhir.ndp.scot/physical-location-type',
    iso3166part1: 'https://hl7.org/fhir/valueset-iso3166-1-2.html',
    iso3166part2: 'https://build.fhir.org/ig/HL7/UTG/NamingSystem-iso3166-2.html',
    ncdsInternalId: 'https://nds.nes.digital/NCDSInternalId',
    gpPracticeCode: 'https://digitalhealthplatform.scot/fhir/GpPracticeCode',
    turasLocationId: 'https://turas.internal/LocationId'
  },
  coding: {
    bodySite: 'http://terminology.hl7.org/CodeSystem/v3-ActSite',
    drugRoute: 'http://terminology.hl7.org/CodeSystem/v3-RouteOfAdministration',
    allergyIntoleranceClinicalStatus: 'http://terminology.hl7.org/CodeSystem/allergyintolerance-clinical',
    allergyIntoleranceVerificationStatus: 'http://terminology.hl7.org/CodeSystem/allergyintolerance-verification',
  },
  extension: {
    nesDisclaimer: 'https://nes.nhs.scot/disclaimer',
  },
  dates: {
    dayJsIsoDateTime: 'YYYY-MM-DDTHH:mm:ssZ',
    dayJsIsoDate: 'YYYY-MM-DD',
    dayJsDateOnly: 'DD MMM YYYY',
    dayJsDateTime: 'DD MMM YYYY HH:mm',
  }
};

export default CONSTANTS;
