import {Location} from "fhir/r4";
import {Col, Row, Table} from "react-bootstrap";
import React from "react";
import {getIdentifierSystemDisplayText} from "../helpers";


export default function LocationDisplayTable(props: { idx: number, locations: Location[], filter: string }): JSX.Element {
  const {idx, locations, filter: filterString} = props;

  const filterTokens = filterString.toLowerCase().split(' ');

  return <Table striped bordered hover size="sm" key={`vst-${idx}`}>
    <thead>
    <tr>
      <th>#</th>
      <th>Name</th>
      <th>Address</th>
      <th>Identifiers</th>
    </tr>
    </thead>

    <tbody>
    {locations.filter(location => {
      const searchableString = (location.name + ' ' + location.address?.line?.join(' ') + location.address?.city)
        .toLowerCase();

      for (let token of filterTokens) {
        if (searchableString.search(token) >= 0)
          return true;
      }

      return false;
    }).map((location, n) =>
      <tr key={`loc-${idx}-${n}`}>
        <td>{n}</td>
        <td>{location.name}<br/>
          <div className="small text-muted">{location.id}</div>
        </td>
        <td>
          {location.address?.line?.join('<br />')}
          {location.address?.city}
        </td>
        <td>

          <Row>
            {location.identifier?.map(lid => <>
              <Col sm={3}>
                <span className="small text-muted">{getIdentifierSystemDisplayText(lid.system)}</span></Col>
              <Col sm={8}> {lid.value}</Col>
            </>)}
          </Row>
        </td>
      </tr>)}
    </tbody>
  </Table>;
}

