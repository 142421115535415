import React, {useState} from "react";
import {Button, Col, Container, Form, Row, Tab, Tabs} from "react-bootstrap";
import SyntaxHighlighter from "react-syntax-highlighter";
import {docco} from "react-syntax-highlighter/dist/esm/styles/hljs";

import {Location} from "fhir/r4";

import {VaccinationAPI} from "../service/VaccinationAPI";
import LocationDisplayTable from "./LocationDisplayTable";


export default function LocationsDisplay(props: { idx: number }): JSX.Element {
  const {idx} = props;

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const [queriedLocations, setQueriedLocations] = useState<Location[] | undefined>(undefined);
  const [locationQueryKey, setLocationQueryKey] = useState<string>('countries');
  const [filterString, setFilterString] = useState<string>('');

  const locationQueries: { [idx: string]: any } = {
    'all': {
      title: 'All locations',
      system: '',
      value: ''
    },
    'countries': {
      title: 'Countries',
      system: 'https://hl7.org/fhir/valueset-iso3166-1-2.html',
      value: ''
    },
    'isd-code': {
      title: 'Locations with ISD code',
      system: 'https://nds.nes.digital/ISD_code',
      value: ''
    },
    'gp-practice': {
      title: 'Locations with GP Practice code',
      system: 'https://digitalhealthplatform.scot/fhir/GpPracticeCode',
      value: ''
    },
  }

  function getKeys(dict: {}): string[] {
    // This is a convenience method, because a {dict} doesn't have any built-in iterators
    const keys: string[] = [];
    for (let k in dict) {
      keys.push(k);
    }
    return keys;
  }

  async function getAndDisplayLocationList() {
    setErrorMessage(undefined);
    setQueriedLocations(undefined);
    setFilterString('');

    const api = new VaccinationAPI(window.sessionStorage.getItem('accessToken') || '');

    const queryData = locationQueries[locationQueryKey];

    try {
      const bundle = await api.getFilteredLocationList(queryData.system, queryData.value, true);
      const locations = bundle.entry?.map(e => e.resource as Location);
      setQueriedLocations(locations || []);
    } catch (e) {
      if (typeof e === 'string')
        setErrorMessage(e);
      else if (e instanceof Error)
        setErrorMessage((e as Error).message)
      else {
        setErrorMessage('Unknown error occurred');
        console.error(e);
      }
    }
  }

  return <>
    <Form>
      <Container className="p-3">
        <Form.Label column={true}>Select Query</Form.Label>
        <Row>
          <Col sm={5}>
            <Form.Select value={locationQueryKey} onChange={e => setLocationQueryKey(e.target.value)}>
              {getKeys(locationQueries).map(k => <option value={k} key={k}>{locationQueries[k].title}</option>)}
            </Form.Select>
          </Col>
          <Col sm={2}>
            <Button variant="outline-primary" className="m-1"
                    onClick={() => getAndDisplayLocationList()}>Refresh data</Button>
          </Col>
        </Row>
      </Container>

      {errorMessage && <Container className="p-3">
        <div className="alert alert-danger">
          {errorMessage}
        </div>
      </Container>}

      {queriedLocations && queriedLocations.length === 0 && <Container className="p-3">
        <p>No locations found matching this query</p>
      </Container>}

      {queriedLocations && queriedLocations.length > 0 && <Container className="p-3">
        <Tabs defaultActiveKey={`lst-locations-viewer`}
              className="mb-3">
          <Tab eventKey={`lst-locations-viewer`}
               title="Locations">
            <Form.Group>
              <Form.Label column={true}>{queriedLocations.length} locations. Search to filter...</Form.Label>
              <Form.Control type="text"
                            value={filterString}
                            onChange={(e) => setFilterString(e.target.value)}
                            placeholder="Filter..."/>
            </Form.Group>

            <LocationDisplayTable idx={idx} locations={queriedLocations} filter={filterString}/>
          </Tab>
          <Tab eventKey={`vst-locations-json`}
               title="JSON">
            <SyntaxHighlighter language="json" style={docco}>
              {JSON.stringify(queriedLocations, null, 2)}
            </SyntaxHighlighter>
          </Tab>
        </Tabs>
      </Container>}

    </Form>
  </>;
}
