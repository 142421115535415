import React, {useEffect, useState} from 'react';
import {Container, Tab, Tabs} from 'react-bootstrap';
import './App.css';

import MainNavbar from "../components/MainNavbar";
import ChooseEnvModal from "../components/ChooseEnvModal";
import ValueSetDisplay from "../components/ValueSetDisplay";
import LocationsDisplay from "../components/LocationsDisplay";

import {switchEnv} from "../config";
import {Environment} from "../types";
import PatientViewer from "../components/PatientViewer";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ClientCredentialsLoginModal from "../components/ClientCredentialsLoginModal";
import {doLogin_ClientCredentials} from "../service/Auth";
import {useNavigate} from "react-router-dom";

dayjs.extend(customParseFormat)

function App() {
  const [envName, setEnvName] = useState<Environment>('dev');
  const [chooseEnvVisible, setChooseEnvVisible] = useState<boolean>(false);
  const [clientCredentialsLoginModalVisible, setClientCredentialsLoginModalVisible] = useState<boolean>(false);

  const navigate = useNavigate();

  function handleChooseEnvClick(env: string) {
    const envName = env as Environment;
    window.sessionStorage.setItem('envName', envName);
    setEnvName(envName);
    setChooseEnvVisible(false);
    switchEnv(envName);
  }

  useEffect(() => {
    const envName = window.sessionStorage.getItem('envName') || 'dev';

    switchEnv(envName as Environment);
    setEnvName(envName as Environment);
  }, []); // NB: empty array important to prevent multiple firing of event

  function handleLoginClientCredentialsClick(clientId: string, clientSecret: string) {
    doLogin_ClientCredentials(clientId, clientSecret)
      .then(response => {
        // TODO: figure out how to pass this into the modal when it's shown
        // window.sessionStorage.setItem('clientCredentialsClientId', clientId)
        setClientCredentialsLoginModalVisible(false);
        const accessToken = response.data.access_token;
        const refreshToken = response.data.refresh_token;

        window.sessionStorage.setItem('accessToken', accessToken);
        window.sessionStorage.setItem('refreshToken', '');

        navigate('/');
      })
      .catch(e => {
        console.error(e);
        // try {
        //   setErrorMessage(JSON.stringify(e));
        // } catch (e2) {
        //   setErrorMessage(e);
        // }
      });
  }

  return <>
    <MainNavbar currentEnvName={envName}
                onEnvButtonClick={() => setChooseEnvVisible(true)}
                onClientCredentialsLoginClick={() => setClientCredentialsLoginModalVisible(true)}
    />

    <Container className="p-3">
      <Tabs id="value-sets-viewer"
            defaultActiveKey={"tab-locations"}
            className="mb-3">

        <Tab title="Locations"
             eventKey={`tab-locations`}>
          <LocationsDisplay idx={100}/>
        </Tab>

        <Tab title="Value Sets"
             eventKey={`tab-value-sets`}>
          <ValueSetDisplay idx={101}/>
        </Tab>

        <Tab title="Inspect Patient"
             eventKey={`tab-patient-viewer`}>
          <PatientViewer/>
        </Tab>

      </Tabs>
    </Container>

    <ChooseEnvModal show={chooseEnvVisible}
                    onHide={() => setChooseEnvVisible(false)}
                    onSubmit={handleChooseEnvClick}/>

    <ClientCredentialsLoginModal show={clientCredentialsLoginModalVisible}
                                 onHide={() => setClientCredentialsLoginModalVisible(false)}
                                 onSubmit={handleLoginClientCredentialsClick}/>
  </>
}

export default App;
