import {Environment} from "./types";

const ncdsApiEnvs = {
  'local': {
    url: `http://localhost:8080/fhir`,
    oauth: {
      authHost: `https://ndp-vaccination-dev.auth.eu-west-2.amazoncognito.com`,
      tokenUrl: `/oauth2/token`,
      loginUrl: `/login`,
      callbackUrl: 'http://localhost:3000/callback',
      clientId: '2ut1goe9r4ksgqsb45kt74sd63',
      userPoolId: 'eu-west-2_ffJqJ0l4Y',
      scopes: ['vaccination.dev.products.ndp.scot/read']
    },
  },
  'dev': {
    url: `https://vaccination.dev.products.ndp.scot/fhir`,
    oauth: {
      authHost: `https://ndp-vaccination-dev.auth.eu-west-2.amazoncognito.com`,
      tokenUrl: `/oauth2/token`,
      loginUrl: `/login`,
      callbackUrl: 'https://vaccination-admin.dev.products.ndp.scot/callback',
      clientId: '2ut1goe9r4ksgqsb45kt74sd63',
      userPoolId: 'eu-west-2_ffJqJ0l4Y',
      scopes: ['vaccination.dev.products.ndp.scot/read']
    },
  },
  'qa': {
    url: `https://vaccination.qa.products.ndp.scot/fhir`,
    oauth: {
      authHost: `https://ndp-vaccination-qa.auth.eu-west-2.amazoncognito.com`,
      tokenUrl: `/oauth2/token`,
      loginUrl: `/login`,
      callbackUrl: 'https://vaccination-admin.qa.products.ndp.scot/callback',
      clientId: '',
      userPoolId: 'eu-west-2_0M8XqGe9V',
      scopes: ['vaccination.qa.products.ndp.scot/read']
    },
  },
  'staging': {
    url: `https://vaccination.staging.products.ndp.scot/fhir`,
    oauth: {
      authHost: `https://ndp-vaccination-staging.auth.eu-west-2.amazoncognito.com`,
      tokenUrl: `/oauth2/token`,
      loginUrl: `/login`,
      callbackUrl: 'https://vaccination-admin.staging.products.ndp.scot/callback',
      clientId: '',
      userPoolId: '',
      scopes: ['vaccination.staging.products.ndp.scot/read']
    },
  },
  'prod': {
    url: `https://vaccination.products.ndp.scot/fhir`,
    oauth: {
      authHost: `https://ndp-vaccination-prod.auth.eu-west-2.amazoncognito.com`,
      tokenUrl: `/oauth2/token`,
      loginUrl: `/login`,
      callbackUrl: 'https://vaccination-admin.products.ndp.scot/callback',
      clientId: '',
      userPoolId: '',
      scopes: ['vaccination.products.ndp.scot/read']
    },
  },
}

const environments = {
  'local': {
    longName: 'Localhost',
    env: {
      name: 'local',
      envDot: '',
      envDash: ''
    },
    ncdsApi: ncdsApiEnvs['local']
  },
  'dev': {
    longName: 'Dev',
    env: {
      name: 'dev',
      envDot: 'dev.',
      envDash: 'dev-'
    },
    ncdsApi: ncdsApiEnvs['dev']
  },
  'local-dev': {
    longName: 'Run local, dev data',
    env: {
      name: 'dev',
      envDot: 'dev.',
      envDash: 'dev-'
    },
    ncdsApi: {
      ...ncdsApiEnvs['dev'],
      ...{
        'oauth': ncdsApiEnvs['local'].oauth
      }
    }
  },
  'qa': {
    longName: 'QA',
    env: {
      name: 'qa',
      envDot: 'qa.',
      envDash: 'qa-'
    },
    ncdsApi: ncdsApiEnvs['qa']
  },
  'staging': {
    longName: 'Staging',
    env: {
      name: 'staging',
      envDot: 'staging.',
      envDash: 'staging-'
    },
    ncdsApi: ncdsApiEnvs['staging']
  },
  'prod': {
    longName: 'PRODUCTION',
    env: {
      name: 'prod',
      envDot: '',
      envDash: ''
    },
    ncdsApi: ncdsApiEnvs['prod']
  }
}


function buildConfig(env: Environment) {
  // safety
  // @ts-ignore
  if (env === 'prod')
    throw new Error('Refusing to start in PROD mode');

  return environments[env];
}

let config = buildConfig('dev');

function switchEnv(env: Environment) {
  // @ts-ignore
  console.warn(`Switching environment to ${env}`);
  config = buildConfig(env);
}


function currentEnv() {
  return config;
}


export {
  switchEnv,
  currentEnv,
  environments,
}
