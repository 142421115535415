import axios, {AxiosRequestConfig} from "axios";
import {Bundle, Patient, ValueSet} from "fhir/r4";

import {currentEnv} from "../config";

export class VaccinationAPI {
  authToken: string;

  constructor(authToken: string) {
    this.authToken = authToken;
  }

  getApiUrl(path: string): string {
    // trim trailing slashes off url root
    let urlRoot = currentEnv().ncdsApi.url;
    while (urlRoot.endsWith('/'))
      urlRoot = urlRoot.substring(urlRoot.length - 1);

    // trim leading slashes off path
    while (path.startsWith('/'))
      path = path.substring(1);

    // build path
    return `${urlRoot}/${path}`
  }

  private getRequestConfig(): AxiosRequestConfig {
    const headers: any = {
      'Content-Type': 'application/json+fhir'
    }

    if (this.authToken)
      headers['Authorization'] = `Bearer ${this.authToken}`

    return {
      headers,
      // validateStatus => true disables validation (because result is always true)
      validateStatus: () => true
    }
  }

  async pingApi(): Promise<boolean> {
    return axios.get(this.getApiUrl('/any-old-url'))
      .then(() => true)
      .catch(e => {
        if (e.response) {
          // all is good, we don't care about the response, just that it exists
          return true;
        }
        console.error(e.message);
        // We're likely seeing a failure to connect
        return false;
      });
  }

  getValueSet(identifier: string): Promise<ValueSet | undefined> {
    const url = this.getApiUrl(`/ValueSet?identifier=${identifier}`);
    const config = this.getRequestConfig();

    console.log("Getting ValueSet from API...");

    return axios.get(url, config)
      .then(response => {
        if (response.status !== 200)
          throw new Error(`Response ${response.status}: ${response.statusText}`)

        // parse response to Bundle object
        const bundle = response.data as Bundle;

        if (bundle.total === 0)
          return undefined;

        const valueSet = bundle.entry![0].resource as ValueSet;

        return valueSet;
      })
      .catch(e => {
        console.error(e);
        throw e;
      });
  }

  getLocationList(): Promise<Bundle> {
    const url = this.getApiUrl(`/Location`);
    const config = this.getRequestConfig();

    console.log("Getting Locations from API...");

    return axios.get(url, config)
      .then(response => {
        if (response.status !== 200)
          throw new Error(`Response ${response.status}: ${response.statusText}`)

        // parse response to Bundle object
        const bundle = response.data as Bundle;
        return bundle;
      })
      .catch(e => {
        console.error(e);
        throw e;
      });
  }

  getFilteredLocationList(system: string, value: string, includeIdentifiers: boolean = false): Promise<Bundle> {
    const url = new URL(this.getApiUrl(`/Location`));
    url.searchParams.append('identifier', `${system}|${value}`);
    if (includeIdentifiers)
      url.searchParams.append('_include', 'Location:identifier');

    const config = this.getRequestConfig();

    console.log("Getting Locations from API...");

    return axios.get(url.href, config)
      .then(response => {
        if (response.status !== 200)
          throw new Error(`Response ${response.status}: ${response.statusText}`)

        // parse response to Bundle object
        const bundle = response.data as Bundle;
        return bundle;
      })
      .catch(e => {
        console.error(e);
        throw e;
      });
  }

  getPatient(chi: string): Promise<Patient | undefined> {
    const url = this.getApiUrl(`/Patient?identifier=${chi}`);
    const config = this.getRequestConfig();

    console.log("Getting Patient by CHI from API...");

    return axios.get(url, config)
      .then(response => {
        if (response.status !== 200)
          throw new Error(`Response ${response.status}: ${response.statusText}`)

        // parse response to Bundle object
        const bundle = response.data as Bundle;

        if (!bundle.total || bundle.total === 0) {
          console.warn(`Patient with CHI ${chi} not found`);
          return undefined;
        }
        if (bundle.total > 1) {
          console.warn(`Expected 1 patient with CHI ${chi}, but found ${bundle.total}`);
          return undefined;
        }

        return bundle.entry![0].resource as Patient;
      })
      .catch(e => {
        console.error(e);
        throw e;
      });
  }

  getImmunizationsForPatient(chi: string): Promise<Bundle> {
    const url = this.getApiUrl(`/Immunization?identifier=${chi}`);
    const config = this.getRequestConfig();

    console.log("Getting Immunizations by CHI from API...");

    return axios.get(url, config)
      .then(response => {
        if (response.status !== 200)
          throw new Error(`Response ${response.status}: ${response.statusText}`)

        // parse response to Bundle object
        const bundle = response.data as Bundle;

        return bundle;
      })
      .catch(e => {
        console.error(e);
        throw e;
      });
  }
}
