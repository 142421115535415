import React, {useState} from "react";
import {Badge, Button, Col, Container, Form, OverlayTrigger, Row, Tab, Tabs, Tooltip} from "react-bootstrap";
import SyntaxHighlighter from "react-syntax-highlighter";
import {docco} from "react-syntax-highlighter/dist/esm/styles/hljs";

import {Address, Immunization, ImmunizationPerformer, Location, Patient, Practitioner} from "fhir/r4";

import {VaccinationAPI} from "../service/VaccinationAPI";
import {compareStringDates, formatStringDate, getContainedById, getIdentifierSystemDisplayText} from "../helpers";
import constants from "../constants";

export default function PatientViewer(): JSX.Element {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const [chiSearchValue, setChiSearchValue] = useState<string>('');
  const [patient, setPatient] = useState<Patient | undefined>();
  const [patientNotFound, setPatientNotFound] = useState<boolean>(false);
  const [immunizations, setImmunizations] = useState<Immunization[] | undefined>();

  async function getAndDisplayPatient() {
    setErrorMessage(undefined);
    setPatientNotFound(false);
    setPatient(undefined);
    setImmunizations(undefined);

    const api = new VaccinationAPI(window.sessionStorage.getItem('accessToken') || '');

    try {

      const results = await Promise.all([
        api.getPatient(chiSearchValue),
        api.getImmunizationsForPatient(chiSearchValue),
      ]);

      // TODO: This could be two separate functions to speed up UI?
      const [apiPatient, immunizationBundle] = results;

      console.warn(apiPatient);
      console.warn(immunizationBundle);

      const immunizationsSorted = immunizationBundle
        .entry?.map(e => e.resource as Immunization)
        .sort((o1, o2) => compareStringDates(o2.occurrenceDateTime!, o1.occurrenceDateTime!, constants.dates.dayJsIsoDateTime));

      if (apiPatient) {
        setPatient(apiPatient);
        setImmunizations(immunizationsSorted)
      } else {
        setPatientNotFound(true);
      }

    } catch (e) {
      if (typeof e === 'string')
        setErrorMessage(e);
      else if (e instanceof Error)
        setErrorMessage((e as Error).message)
      else {
        setErrorMessage('Unknown error occurred');
        console.error(e);
      }
    }
  }

  function getLocation(immunization: Immunization): Location | undefined {
    const searchId = immunization.location?.reference;
    if (!searchId) return;
    return getContainedById<Location>(searchId, immunization, 'Location');
  }

  function getPerformer(immunization: Immunization, performerReference: ImmunizationPerformer): Practitioner | undefined {
    const searchId = performerReference.actor.reference;
    if (!searchId) return;
    return getContainedById<Practitioner>(searchId, immunization, 'Practitioner');
  }

  function ImmunizationRow(props: { immunization: Immunization }) {
    const {immunization} = props;
    const location = getLocation(immunization);

    const isClinicalTrial = immunization.reasonCode &&
      immunization.reasonCode.filter(rc => rc.coding![0].code === '110465008').length > 0;
    const isBooster = immunization.extension &&
      immunization.extension.filter(ext => ext.url === 'https://vaccination.products.ndp.scot/Booster').length > 0;


    function renderAddressTooltip(address: Address | undefined, props: any): JSX.Element {
      if (!address) return <Tooltip id="address-tooltip" {...props}>
        Address not found
      </Tooltip>;

      return <Tooltip id="address-tooltip" {...props}>
        <div>
          {address.line?.join(',')} <br/>
          {address.city} <br/>
          {address.postalCode}
        </div>
      </Tooltip>;
    }

    return <tr className="py-1 border-bottom">
      <td>
        {formatStringDate(immunization.occurrenceDateTime, constants.dates.dayJsIsoDateTime, constants.dates.dayJsDateTime)}<br/>
        {immunization.status === 'completed'
          ? <span className="small text-muted">{immunization.status}</span>
          : <span className="small text-warning">{immunization.status}</span>}
      </td>
      <td>{immunization.vaccineCode.text}
        {immunization.vaccineCode.coding?.map((coding, n) =>
          <div key={`immcode-${immunization}-${n}`}>
            <div>{coding.display}</div>
            <div>
              <span className="text-primary small">{coding.code}</span>&nbsp;
              <span className="text-muted small">{getIdentifierSystemDisplayText(coding.system)}</span>
            </div>
          </div>)}

        <div key={`imm-proto-${immunization}`}>
          {isBooster && <><Badge bg="info">
            Booster
          </Badge> {' '}</>}
          {isClinicalTrial && <><Badge bg="warning">
            Clinical Trial
          </Badge> {' '}</>}

          {immunization.protocolApplied?.map((protocol, n) => {
            return <>
              <Badge bg="secondary" key={`imm-proto-${immunization}-${n}`}>
                {protocol.targetDisease![0].coding![0].display} {' '}
                <Badge pill>{protocol.doseNumberPositiveInt}</Badge>
              </Badge>{' '}
            </>;
          })}
        </div>

      </td>
      <td>
        {location && <div>
          <OverlayTrigger
            placement="right"
            overlay={(props) => renderAddressTooltip(location.address, props)}>
            <div>{location.name}</div>
          </OverlayTrigger>
        </div>}
      </td>

      <td>
        {immunization.performer?.map((ip, n) => {
          const performer = getPerformer(immunization, ip);
          if (!performer)
            return <div key={`ip-${n}`}>not found</div>;
          const name = performer.name![0];
          return <div key={`ip-${n}`}>
            <div className="text-primary">{name.prefix} {name.given?.join(' ')} {name.family}</div>
            {performer.identifier?.map((identifier, n2) =>
              <div>
                <div className="text-secondary" key={`ip-${n}-id-${n2}`}>{identifier.value}</div>
                <div className="text-muted small">{getIdentifierSystemDisplayText(identifier.system)}</div>
              </div>
            )}
          </div>
        })}
      </td>
    </tr>
  }

  return <>
    <Form>
      <Container className="p-3">
        <Form.Label column={true}>Enter CHI</Form.Label>
        <Row>
          <Col sm={3}>
            <Form.Control type="text"
                          value={chiSearchValue}
                          onChange={(e) => setChiSearchValue(e.target.value)}
                          placeholder="CHI..."/>
          </Col>
          <Col sm={2}>
            <Button variant="outline-primary" className="m-1"
                    onClick={() => getAndDisplayPatient()}>Refresh data</Button>
          </Col>
        </Row>
      </Container>

      {errorMessage && <Container className="p-3">
        <div className="alert alert-danger">
          {errorMessage}
        </div>
      </Container>}

      {patientNotFound && <Container className="p-3">
        <div className="alert alert-danger">
          Patient not found
        </div>
      </Container>}

      {patient && <Container className="p-3">
        <Tabs defaultActiveKey={`pt-viewer`}
              className="mb-3">

          <Tab eventKey={`pt-viewer`}
               title="Patient">
            <dl>
              <dt>Name</dt>
              {patient.name?.map((name, n) =>
                <dd key={`pname-${n}`}><strong>{name.family?.toUpperCase()}</strong> {name.given?.join(' ')}
                </dd>)}

              <dt>Identifiers</dt>
              {patient.identifier?.map((identifier, n) =>
                <dd key={`pid-${n}`}>{identifier.value}
                  <span className="text-muted small px-1">{getIdentifierSystemDisplayText(identifier.system)}</span>
                </dd>)}

              <dt>DoB</dt>
              <dd>{formatStringDate(patient.birthDate, constants.dates.dayJsIsoDate, constants.dates.dayJsDateOnly)}</dd>

              <dt>Gender</dt>
              <dd>{patient.gender}</dd>

              <dt>Address</dt>
              {patient.address?.map((address, n) =>
                <dd key={`padd-${n}`}>{address.line?.join(',')} {address.postalCode} {address.city}</dd>)}
            </dl>
          </Tab>

          <Tab eventKey={`pt-json`}
               title="JSON">
            <SyntaxHighlighter language="json" style={docco}>
              {JSON.stringify(patient, null, 2)}
            </SyntaxHighlighter>
          </Tab>

          <Tab eventKey={`pt-immunization-viewer`}
               title="Immunizations">

            <table>
              <thead>
              <tr>
                <th>Date</th>
                <th>Vaccine</th>
                <th>Location</th>
                <th>Performer</th>
              </tr>
              </thead>

              <tbody>
              {immunizations?.map(immunization =>
                <ImmunizationRow immunization={immunization}
                                 key={`imm-row-${immunization.id}`}/>)}
              </tbody>
            </table>
          </Tab>

          <Tab eventKey={`pt-imm-json`}
               title="JSON">
            <SyntaxHighlighter language="json" style={docco}>
              {JSON.stringify(immunizations, null, 2)}
            </SyntaxHighlighter>
          </Tab>
        </Tabs>
      </Container>}

    </Form>
  </>;
}
