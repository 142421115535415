import dayjs, {OpUnitType} from "dayjs";

import constants from "./constants";
import {DomainResource, FhirResource} from "fhir/r4";

export function getIdentifierSystemDisplayText(system: string | undefined): string {
  if (!system) return '';

  const systems: { [key: string]: string } = {}
  systems[constants.identifierSystem.chiNumber] = 'CHI Number';
  systems[constants.identifierSystem.gmcNumber] = 'GMC Number';
  systems[constants.identifierSystem.nmcNumber] = 'NMC Number';
  systems[constants.identifierSystem.snomedCt] = 'SNOMED CT code';
  systems[constants.identifierSystem.healthBoardCypher] = 'Health board cypher';
  systems[constants.identifierSystem.isdCode] = 'ISD Code';
  systems[constants.identifierSystem.iso3166part1] = 'ISO 3166 part 1';
  systems[constants.identifierSystem.iso3166part2] = 'ISO 3166 part 2';
  systems[constants.identifierSystem.locationType] = 'NES Location type';
  systems[constants.identifierSystem.ncdsInternalId] = 'NCDS Internal ID';
  systems[constants.identifierSystem.gpPracticeCode] = 'GP Practice Code';
  systems[constants.identifierSystem.turasLocationId] = 'Turas Location ID';

  if (system in systems)
    return systems[system];

  return system;
}

export function formatStringDate(ds: string | undefined, inFormat: string, outFormat: string): string {
  if (!ds) return "";

  const d1 = dayjs(ds, inFormat);
  return d1.format(outFormat);
}

export function compareStringDates(ds1: string, ds2: string, format: string, granularity: OpUnitType = 'millisecond'): number {
  const d1 = dayjs(ds1, format);
  const d2 = dayjs(ds2, format);

  if (d1.isSame(d2, granularity))
    return 0;
  if (d1.isBefore(d2, granularity))
    return -1;
  // if (d1.isAfter(d2, granularity))
  return 1;
}

export function getContainedById<T extends FhirResource>(searchId: string, parent: DomainResource, resourceType: string): T | undefined {

  if (searchId.startsWith('#')) {
    // trim off leading # and search by resource ID
    searchId = searchId.substring(1);
    const filtered = parent
      .contained?.filter(c => c.id === searchId && c.resourceType === resourceType)
      .map(c => c as T);

    if (!filtered) {
      console.warn(`Contained ${resourceType} by ID ${searchId} not found`);
      return;
    }

    return filtered[0];
  } else {
    console.warn(`Don't know how to search for ${resourceType} by referenced ID ${searchId}`);
  }
}


// Required to pass validation
export default {}
