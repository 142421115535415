import {AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserSession,} from 'amazon-cognito-identity-js';
import {currentEnv} from "../config";
import axios from "axios";


export function getLoginUiUrl() {
  const cfg = currentEnv().ncdsApi.oauth;

  const url = new URL(`${cfg.authHost}${cfg.loginUrl}`);
  url.searchParams.append('client_id', cfg.clientId);
  url.searchParams.append('redirect_uri', cfg.callbackUrl);
  url.searchParams.append('scope', cfg.scopes.join('+'));
  url.searchParams.append('response_type', 'code');

  const fullUrl = url.href;

  return fullUrl;
}

export function handleCallback(code: string): Promise<any> {
  const cfg = currentEnv().ncdsApi.oauth;
  const url = `${cfg.authHost}${cfg.tokenUrl}`;
  const headers = {
    Accept: '*/*',
    'Content-Type': 'application/x-www-form-urlencoded'
  }
  const data = new URLSearchParams();
  data.append('grant_type', 'authorization_code');
  data.append('client_id', cfg.clientId);
  data.append('code', code);
  data.append('scope', cfg.scopes.join('+'));
  data.append('redirect_uri', cfg.callbackUrl);

  return axios.post(url, data, {headers});
}

export function doLogin(username: string, password: string) {
  const cfg = currentEnv().ncdsApi.oauth;
  const userPool = new CognitoUserPool({
    UserPoolId: cfg.userPoolId,
    ClientId: cfg.clientId,
  });

  const authDetails = new AuthenticationDetails({Username: username, Password: password});

  const cognitoUser = new CognitoUser({Username: username, Pool: userPool});

  return new Promise<CognitoUserSession>((resolve, reject) => {
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: (session: CognitoUserSession, userConfirmationNecessary?: boolean) => {

        resolve(session);
        // const accessToken = session.getAccessToken().getJwtToken();
        // const idToken = session.getIdToken().getJwtToken();
        // console.warn(accessToken);
        //
        // return accessToken;
        //
        // //POTENTIAL: Region needs to be set if not already set previously elsewhere.
        // AWS.config.region = '<region>';
        //
        // AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        //   IdentityPoolId: '...', // your identity pool id here
        //   Logins: {
        //     // Change the key below according to the specific region your user pool is in.
        //     'cognito-idp.<region>.amazonaws.com/<YOUR_USER_POOL_ID>': session
        //       .getIdToken()
        //       .getJwtToken(),
        //   },
        // });

        /*//refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
        AWS.config.credentials.refresh(error => {
          if (error) {
            console.error(error);
          } else {
            // Instantiate aws sdk service objects now that the credentials have been updated.
            // example: var s3 = new AWS.S3();
            console.log('Successfully logged!');
          }
        });*/
      },
      onFailure: (err: any) => reject(err)
    })
  });
}

export function doLogin_ClientCredentials(clientId: string, clientSecret: string) {
  const cfg = currentEnv().ncdsApi.oauth;
  const url = `${cfg.authHost}${cfg.tokenUrl}`;
  const headers = {
    Accept: '*/*',
    'Content-Type': 'application/x-www-form-urlencoded'
  }
  const data = new URLSearchParams();
  data.append('grant_type', 'client_credentials');
  data.append('client_id', clientId);
  data.append('client_secret', clientSecret);
  data.append('scope', cfg.scopes.join('+'));
  data.append('redirect_uri', cfg.callbackUrl);

  return axios.post(url, data, {headers});
}
